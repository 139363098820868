






























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { cloneDeep } from 'lodash';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ProspectService from '@/services/crm/prospect.service';
import ProspectModel from '@/models/crm/prospect.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import SettingsService from '@/services/crm/settings.service';
import SettingsModel from '@/models/crm/settings.model';

@Component({
  components: {
    Field,
  },
})
export default class CrmChatProspectForm extends Vue {
  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  formIsValid = true;

  model: ProspectModel = plainToClass(ProspectModel, {
    codProspect: null,
    cnpj: null,
    fantasia: null,
    razaoSocial: null,
    contato: null,
    ddi: '55',
    telefone: null,
  });

  telephoneMaskActive = false;

  settings: SettingsModel | null = null;

  @Prop()
  defaultTelephone!: string;

  async created(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
    this.rules = {
      cnpj: [[ValidationRules.cnpj, this.settings?.flagTornarCnpjProspectObrigatorio]],
      fantasia: [ValidationRules.required],
      razaoSocial: [ValidationRules.required],
      contato: [ValidationRules.required],
    };
    if (this.settings.flagTelefoneProspectObrigatorio) {
      this.rules.telefone = [ValidationRules.phone, ValidationRules.required];
    }
  }

  async mounted(): Promise<void> {
    this.model.telefone = this.defaultTelephone;

    setTimeout(() => {
      this.telephoneMaskActive = true;
    }, 150);
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        const model = cloneDeep(this.model);
        model.cnpj = model.cnpj ? model.cnpj.replace(/\D/g, '') : model.cnpj;
        model.telefone = model.telefone.replace(/\D/g, '');
        const result = await this.prospectService.create(model);

        this.model.codProspect = result.toString();

        this.$notify.success(this.$t('crm.prospectForm.successfullySave'));

        this.$emit('complete', this.model);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    } else if (!this.model.telefone && this.settings?.flagTelefoneProspectObrigatorio) {
      this.$notify.warn(this.$t('crm.prospectForm.warn.phoneIsRequired'));
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  rules: IKeyValue<unknown> = {};

  get cnpjCpfMask(): string {
    if (this.model.cnpj) {
      if (this.model.cnpj.length === 17 || (this.model.cnpj.length === 14 && !this.model.cnpj.includes('.'))) {
        return '##.###.###/####-##';
      }
      if (this.model.cnpj.length === 11 || (this.model.cnpj.length === 8 && !this.model.cnpj.includes('.'))) {
        return '###.###.###-##';
      }
    }
    return '';
  }

  get telephoneMask(): string | boolean {
    const parsedNumber = (this.model.telefone || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    return '(##) ####-####';
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.prospectFormContainer,
      canCancel: false,
    });
  }
}
