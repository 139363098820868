


























































































































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { cloneDeep, sortBy } from 'lodash';
import { GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core';
import { AgGridVue } from '@ag-grid-community/vue';
import { VueMaskFilter } from 'v-mask';
import ClientModel from '@/models/crm/client.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import LocalizationService from '@/services/localization.service';
import SessionService from '@/services/session.service';
import StateModel from '@/models/state.model';
import CityModel from '@/models/city.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import Tooltip from '@/components/tooltip.vue';
import ClientService from '@/services/crm/client.service';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import LeadService from '@/services/crm/lead.service';
import LeadModel from '@/models/crm/lead.model';
import ContactChannelService from '@/services/crm/contact-channel.service';
import ContactChannelModel from '@/models/crm/contact-channel.model';

@Component({
  components: {
    AgGridWrapper,
    AgGridVue,
    Tooltip,
  },
})
export default class CrmAdvancedSearch extends Vue {
  @inject(InjectionIdEnum.LocalizationService)
  private localizationService!: LocalizationService;

  @inject(InjectionIdEnum.CrmLeadService)
  private leadService!: LeadService;

  @inject(InjectionIdEnum.CrmContactChannelService)
  private contactChannelService!: ContactChannelService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  formIsValid = true;

  model: IKeyValue<string | null> = {
    cnpj: null,
    companyName: null,
    clientCode: null,
    legalName: null,
    state: null,
    city: null,
    lead: null,
    contactChannel: null,
  };

  stateOptions: StateModel[] = [];

  cityOptions: CityModel[] = [];

  leadOptions: LeadModel[] = [];

  contactChannelOptions: ContactChannelModel[] = [];

  cityLoading = false;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'nome', sort: 'asc' }],
    columnDefs: [],
  };

  items: ClientModel[] = [];

  async mounted(): Promise<void> {
    this.stateOptions = sortBy(await this.localizationService.getStates(), 'nome');
    this.leadOptions = await this.leadService.getLeads();
    this.contactChannelOptions = await this.contactChannelService.getContactChannels();

    let columnDefs = [
      {
        headerName: `${this.$t('crm.advancedSearch.grid.cnpj')}`,
        field: 'cnpjCpf',
        valueGetter: (params: ValueGetterParams): string => {
          const mask = params.data.cnpjCpf && params.data.cnpjCpf.length > 11 ? '##.###.###/####-##' : '###.###.###-##';
          return VueMaskFilter(params.data.cnpjCpf, mask);
        },
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: `${this.$t('crm.advancedSearch.grid.companyName')}`,
        field: 'nomeFantasia',
        minWidth: 130,
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.advancedSearch.grid.legalName')}`,
        field: 'nome',
        minWidth: 130,
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.advancedSearch.grid.city')}`,
        field: 'cidade',
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.advancedSearch.grid.state')}`,
        field: 'estado',
        maxWidth: 70,
      },
    ];

    if (this.isProspectType) {
      columnDefs = [
        {
          headerName: `${this.$t('crm.advancedSearch.grid.clientCode')}`,
          field: 'codCliente',
          maxWidth: 130,
        },
        ...columnDefs,
        {
          headerName: `${this.$t('crm.advancedSearch.grid.leadType')}`,
          field: 'lead.descricao',
          maxWidth: 130,
        },
        {
          headerName: `${this.$t('crm.advancedSearch.grid.contactChannel')}`,
          field: 'formaCaptacao.descricao',
          maxWidth: 130,
        },
      ];
    }

    this.gridSettings.columnDefs = columnDefs;
  }

  onCellClick(event: IGridCellEvent<ClientModel>): void {
    if (!event.data) {
      return;
    }

    this.sessionService.activeClient = event.data;
    if (this.clientType === ClientTypeEnum.Client) {
      this.sessionService.clientType = ClientTypeEnum.Client;
    } else {
      this.sessionService.clientType = ClientTypeEnum.Prospect;
    }

    this.$emit('select', event.data);
  }

  @Emit('cancel')
  onCancel(): void {
    this.formIsValid = false;
  }

  async onStateChange(uf: string): Promise<void> {
    this.cityOptions = [];
    this.model.city = null;

    if (!uf) {
      return;
    }

    this.cityLoading = true;
    try {
      this.cityOptions = sortBy(await this.localizationService.getCities(uf), 'nome');
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.cityLoading = false;
    }
  }

  async onSearch(): Promise<void> {
    this.gridSettings.loading = true;
    try {
      const params = cloneDeep(this.model);

      if (params.cnpj) {
        params.cnpj = params.cnpj.replace(/\D/g, '');
      }

      this.items = await this.clientService.advancedSearch(params, this.clientType);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.gridSettings.loading = false;
    }
  }

  onExport(): void {
    if (this.grid) {
      this.grid.api.exportDataAsExcel({
        allColumns: true,
        author: 'IBtech',
        sheetName: this.isProspectType ? 'Prospects' : 'Clientes',
        fileName: this.isProspectType
          ? ClientService.generateProspectExportFilename(new Date())
          : ClientService.generateClientExportFilename(new Date()),
      });
    }
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  get isClientType(): boolean {
    return this.clientType === ClientTypeEnum.Client;
  }

  get cnpjCpfMask(): string {
    if (this.model.cnpj) {
      if (this.model.cnpj.length === 17 || (this.model.cnpj.length === 14 && !this.model.cnpj.includes('.'))) {
        return '##.###.###/####-##';
      } if (this.model.cnpj.length === 11
      || (this.model.cnpj.length === 8 && !this.model.cnpj.includes('.'))) {
        return '###.###.###-##';
      }
    }
    return '';
  }
}
