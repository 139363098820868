





















































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ConversationService from '@/services/crm/conversation.service';
import { IKeyValue } from '@/interfaces/key-value.interface';
import UserModel from '@/models/user.model';
import ConversationDepartmentModel from '@/models/crm/conversation-department.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';

@Component({
  components: {
    Field,
  },
})
export default class CrmChatSelectDepartmentForm extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @Prop({ default: null })
  openConversation!: string | null;

  @Prop({ default: null })
  userContactInfo!: UserContactInfo | null;

  formIsValid = true;

  model: IKeyValue<string | null> = {
    department: null,
    attendant: null,
  };

  rules = {
    department: [ValidationRules.required],
  };

  departmentOptions: ConversationDepartmentModel[] = [];

  attendantOptions: UserModel[] = [];

  filteredAttendants: UserModel[] = [];

  async mounted(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      const getTemplatesTask = this.loadDepartments();
      const getAttendantsTask = this.loadAttendants();
      await Promise.all([getTemplatesTask, getAttendantsTask]);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
    if (this.userContactInfo) {
      const selectedUser = this.attendantOptions.find((user) => user.id === this.userContactInfo?.id);

      if (selectedUser) {
        const filas = selectedUser?.filasAtendidas || '';
        this.departmentOptions = this.departmentOptions.filter((dep) => (
          filas.split(',').some((filaId) => filaId === dep.id.toString())
        ));
      }
    }
    this.filteredAttendants = this.attendantOptions;
  }

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        this.$emit('select', this.model);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  private async loadDepartments(): Promise<void> {
    this.departmentOptions = await this.conversationService.getDepartments();
  }

  private async loadAttendants(): Promise<void> {
    this.attendantOptions = await this.conversationService.getAttendants();
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.selectDepartmentFormContainer,
      canCancel: false,
    });
  }

  onChangeDepartment(event: ConversationDepartmentModel): void {
    this.filteredAttendants = this.attendantOptions.filter((user) => (
      user.filasAtendidas?.split(',').some((fila) => fila === event.id.toString())
    ));
  }
}
