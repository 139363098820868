import { render, staticRenderFns } from "./call-center-user-status-dialog.vue?vue&type=template&id=5e4ae0f0&scoped=true&"
import script from "./call-center-user-status-dialog.vue?vue&type=script&lang=ts&"
export * from "./call-center-user-status-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./call-center-user-status-dialog.vue?vue&type=style&index=0&id=5e4ae0f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4ae0f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardText,VContainer,VIcon,VSimpleTable})
