






































import { Vue, Component, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ConversationService from '@/services/crm/conversation.service';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';

@Component({
  components: {
    Field,
  },
})
export default class CrmChatSelectTemplateForm extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  formIsValid = true;

  model: IKeyValue<string | null> = {
    template: null,
  };

  rules = {
    template: [ValidationRules.required],
  };

  templateOptions: ConversationTemplateModel[] = [];

  async mounted(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      const getTemplatesTask = this.loadTemplates();
      await Promise.all([getTemplatesTask]);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        this.$emit('select', this.model.template);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  private async loadTemplates(): Promise<void> {
    this.templateOptions = await this.conversationService.getTemplates();
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.selectTemplateFormContainer,
      canCancel: false,
    });
  }
}
